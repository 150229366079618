<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Body Types</h1>
            <router-link to="/business/addbodytype"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add Body Type</v-btn
              ></router-link
            >
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Image</th>
                  <th class="text-left">Body Type</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in bodyTypes"
                  :key="index + 'bodyType'"
                  class="rowBox"
                >
                  <td width="120">
                    <img :src="data.image" alt="" width="100" />
                  </td>
                  <td>{{ data.bodyTypeName }}</td>
                  <td width="10">
                    <div class="d-flex">
                      <router-link :to="`/business/addbodytype/${data._id}`"
                        ><v-icon> mdi-square-edit-outline </v-icon></router-link
                      >
                      <v-icon
                        class="ml-3"
                        v-if="!loadingDelete || id != data._id"
                        @click="removeBodyType(data._id)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-progress-circular
                        class="ml-3"
                        indeterminate
                        v-if="loadingDelete && id == data._id"
                        size="20"
                        width="2"
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="loading">
                <tr>
                  <td>
                    Loading
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="3">
                    <ul class="pagination">
                      <li v-if="pagination.previous">
                        <v-icon @click="prev(pagination.previous)">
                          mdi-chevron-left
                        </v-icon>
                      </li>
                      <li>
                        <span v-if="pagination.previous"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.next"
                          >{{
                            pagination.next - 1 > 1
                              ? pagination.next - 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        {{ pagination.total }} Items
                      </li>
                      <li v-if="pagination.next">
                        <v-icon @click="next(pagination.next)">
                          mdi-chevron-right
                        </v-icon>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BodyType",
  data: () => ({
    bodyTypes: [],
    loading: true,
    loadingDelete: false,
    pagination: undefined,
    limit: 10,
    page: 1,
    id: undefined,
  }),
  methods: {
    ...mapActions(["getBodyTypeList", "deleteBodyType"]),
    async removeBodyType(id) {
      this.loadingDelete = true;
      this.id = id;
      await this.deleteBodyType(id);
      this.loadingDelete = false;
      this.bodyTypes = this.allBodyTypes.results;
      this.pagination = this.allBodyTypes.pagination;
    },
    async next(data) {
      this.loading = true;
      await this.getBodyTypeList({ limit: this.limit, page: data });
      this.bodyTypes = this.allBodyTypes.results;
      this.pagination = this.allBodyTypes.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getBodyTypeList({ limit: this.limit, page: data });
      this.bodyTypes = this.allBodyTypes.results;
      this.pagination = this.allBodyTypes.pagination;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allBodyTypes"]),
  },
  async created() {
    await this.getBodyTypeList({ limit: this.limit, page: this.page });
    this.bodyTypes = this.allBodyTypes.results;
    this.pagination = this.allBodyTypes.pagination;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  td {
    padding: 10px !important;
  }
}
a {
  text-decoration: none;
}
</style>